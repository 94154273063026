import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestoreDocument, AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SelectedSchool } from '../enum/selected-school';
import { Contact } from '../models/contact.model';
import { SelectSchoolService } from './select-school.service';

@Injectable({
  providedIn: 'root'
})
export class ContactService implements OnDestroy {

  ContactsCollection: AngularFirestoreCollection<Contact>;
  Contacts: Observable<Contact[]>;
  ContactDoc?: AngularFirestoreDocument<Contact>;
  selectedSchool: SelectedSchool;
  dbPath: string;

  constructor(private db: AngularFirestore, 
    private selectSchoolService: 
    SelectSchoolService) { 

      this.selectedSchool = this.selectSchoolService.getSchool();
      this.dbPath = 'Contacts_' + this.selectedSchool;
      this.ContactsCollection = this.db.collection(`${this.dbPath}/`);
  
      this.Contacts = this.ContactsCollection.snapshotChanges().pipe(map((changes: any[]) => {
        return changes.map(a => {
          const data = a.payload.doc.data() as Contact;
          data.id = a.payload.doc.id;
          return data;
        });
      }));
      console.log(this.selectedSchool)
      console.log(this.dbPath)
      console.log(this.ContactsCollection)
      console.log(this.Contacts)
    }

    getAllContacts(){
      return this.Contacts;
    }
  
    addContact(contact: Contact){
      this.ContactsCollection.add(contact);
    }
  
    deleteContact(contact: Contact){
      this.ContactDoc = this.db.doc(`${this.dbPath}/${contact.id}`);
      this.ContactDoc.delete();
    }
  
    updateContact(contact: Contact, newContact: Contact){
      this.ContactDoc = this.db.doc(`${this.dbPath}/${contact.id}`)
      this.ContactDoc.update(newContact);
    }
  
    ngOnDestroy(): void {
    }
}
