import { Injectable } from '@angular/core';
import { SelectedSchool } from '../enum/selected-school';

@Injectable({
  providedIn: 'root'
})
export class SelectSchoolService {

  constructor() { }

  setSchool(school: SelectedSchool): void {
    console.log(school)
    let selectedSchool = school;
    sessionStorage.setItem('school', JSON.stringify(selectedSchool));
  }

  clearSchool(): void {
    sessionStorage.setItem('school', 'null');
  }

  getSchool() {
    return JSON.parse(sessionStorage.getItem('school')!);
  }
}
