import { Component, Input, OnInit } from '@angular/core';
import { FileUpload } from 'src/app/models/file-upload.model';
import { GalleryFileLoaderService } from 'src/app/services/gallery-file-loader.service';
import { LightgalleryModule } from 'lightgallery/angular';


@Component({
  selector: 'app-gallery-upload-details',
  templateUrl: './gallery-upload-details.component.html',
  styleUrls: ['./gallery-upload-details.component.sass'],
  providers: [GalleryFileLoaderService]
})
export class GalleryUploadDetailsComponent implements OnInit {

  @Input()
  fileUpload!: FileUpload;

  @Input()
  filesPath: string = '';
  constructor(private uploadService: GalleryFileLoaderService) { }
  ngOnInit(): void {
  }

  deleteFileUpload(fileUpload: FileUpload): void {
    this.uploadService.deleteFile(fileUpload, this.filesPath);
  }
}


