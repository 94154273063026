import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectedSchool } from 'src/app/enum/selected-school';
import { SelectSchoolService } from 'src/app/services/select-school.service';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.sass']
})
export class RedirectComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private selectSchool: SelectSchoolService,
    private router: Router) {
      const school = this.route.snapshot.paramMap.get('school');
      if(school == null) this.router.navigateByUrl("")
      else{
        if(school =='matejki') {
          this.selectSchool.setSchool(SelectedSchool.matejki);
          this.router.navigateByUrl('/aktualnosci')
        }
        else if(school == 'krakowska') {
          this.selectSchool.setSchool(SelectedSchool.krakowska);
          this.router.navigateByUrl('/aktualnosci')
        }
        else console.log('school select error')
      }
    }

  ngOnInit(): void {
  }

}
