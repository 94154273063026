import { Component, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import { AuthService } from '../services/auth.service';
import { SelectSchoolService } from '../services/select-school.service';
import { SelectedSchool } from '../enum/selected-school';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.sass']
})
export class MenuComponent implements OnInit {
  selectedSchool: SelectedSchool

  constructor(private router: Router, private authService: AuthService, private selectSchoolService: SelectSchoolService) {
    this.selectedSchool = this.selectSchoolService.getSchool()
   }

  ngOnInit(): void {
  }

  isKrakowska(): boolean{
    if(this.selectedSchool == SelectedSchool.krakowska) return true
    return false
  }

  navigateToGallery(): string {
    return '/galeria'
  }
  navigateToInfo(): string {
    return '/o-nas'
  }
  navigateToParentSection(): string {
    return '/dla-rodzicow'
  }
  navigateToDownloads(): string {
    return '/do-pobrania'
  }
  navigateToContact(): string {
    return '/kontakt'
  }
  navigateToNews(): string{
    return '/aktualnosci'
  }
  navigateToChoice(): string{
    return ''
  }
  navigateToLogin(): string{
    return '/login'
  }
  navigateToLogout(): string {
    this.authService.SignOut()
    return '/login'
  }
  GetActiveUser(): boolean{
    if(this.authService.GetActiveUser() !== null) return true
    return false
  }


}
