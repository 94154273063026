import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { HasSelectedSchoolGuard } from './guard/has-selected-school.guard';
import { AktualnosciComponent } from './pages/aktualnosci/aktualnosci.component';
import { DlaRodzicowComponent } from './pages/dla-rodzicow/dla-rodzicow.component';
import { DoPobraniaComponent } from './pages/do-pobrania/do-pobrania.component';
import { GaleriaComponent } from './pages/galeria/galeria.component';
import { KontaktComponent } from './pages/kontakt/kontakt.component';
import { LoginComponent } from './pages/login/login.component';
import { ONasComponent } from './pages/o-nas/o-nas.component';
import { RedirectComponent } from './pages/redirect/redirect.component';
import { StronaGlownaComponent } from './pages/strona-glowna/strona-glowna.component';
import { UkrainaComponent } from './pages/ukraina/ukraina.component';


const routes: Routes = [

  {
    path:'',
    component: StronaGlownaComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path:'galeria',
    component: GaleriaComponent,
    canActivate: [HasSelectedSchoolGuard]
  },
  {
    path:'kontakt',
    component: KontaktComponent,
    canActivate: [HasSelectedSchoolGuard]
  },
  {
    path:'o-nas',
    component: ONasComponent,
    canActivate: [HasSelectedSchoolGuard]
  },
  {
    path:'dla-rodzicow',
    component: DlaRodzicowComponent,
    canActivate: [HasSelectedSchoolGuard]
  },
  {
    path:'do-pobrania',
    component: DoPobraniaComponent,
    canActivate: [HasSelectedSchoolGuard]
  },
  {
    path:'aktualnosci',
    component: AktualnosciComponent,
    canActivate: [HasSelectedSchoolGuard]
  },
  {
    path:'redirect/:school',
    component: RedirectComponent
  },
  {
    path:'ukraina',
    component: UkrainaComponent
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
