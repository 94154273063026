import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {

  constructor(public authService: AuthService, private router: Router) { }

  ngOnInit(): void {
  }

  navigateToChoice(): void{
    this.router.navigateByUrl("",{ skipLocationChange: true })
  }

  GetActiveUser(): boolean{
    if(this.authService.GetActiveUser() !== null) return true
    return false
  }
}
