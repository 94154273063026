import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SelectedSchool } from 'src/app/enum/selected-school';
import { SelectSchoolService } from 'src/app/services/select-school.service';


@Component({
  selector: 'app-strona-glowna',
  templateUrl: './strona-glowna.component.html',
  styleUrls: ['./strona-glowna.component.sass']
})
export class StronaGlownaComponent implements OnInit {

  constructor(private router: Router, private selectSchool: SelectSchoolService) { }

  ngOnInit(): void {
    this.selectSchool.clearSchool();
  }
}
