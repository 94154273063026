<app-menu></app-menu>

<div class="main-admin-menu" *ngIf="GetActiveUser()">
    <div class="admin-menu">
        <h5>Witaj!&nbsp;<strong>{{displayName()}}</strong></h5>
        <button type="button" class="btn btn-info" (click)="navigateToLogout()">Wyloguj</button>
    </div>
</div>

<div fxHide.sm fxHide.xs class="Main">
    <div class="mapa" fxLayout="row">
        <div fxLayout="column" fxFlex="50%">
            <h1>Tutaj nas znajdziesz</h1>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2522.357300567906!2d19.084774951467327!3d50.787486570802486!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4710b5fe25841185%3A0xf425b9dcf347e43a!2sJana%20Matejki%2067%2C%2042-200%20Cz%C4%99stochowa!5e0!3m2!1sen!2spl!4v1645907559219!5m2!1sen!2spl"
                width="800" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>


        </div>
        <div fxLayout="column" fxFlex="">
            <div fxLayout="column" class="kontakt-main">
                <div fxLayout="row" class="card kontakt" *ngFor="let contact of contacts">
                    <div class="card-body">
                        <div [innerHTML]="contact.text | safeHtml" class="card-text"></div>
                        <button type="button" class="btn btn-warning" (click)="openModalEdit(contact)" *ngIf="GetActiveUser()">Edytuj</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>


<!--Mobile-->

<div fxHide.gt-sm class="main-mobile">
    <div fxLayout="column">
        <div fxLayout="row">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2522.357300567906!2d19.084774951467327!3d50.787486570802486!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4710b5fe25841185%3A0xf425b9dcf347e43a!2sJana%20Matejki%2067%2C%2042-200%20Cz%C4%99stochowa!5e0!3m2!1sen!2spl!4v1645907559219!5m2!1sen!2spl "
                width="450 " height="450 " style="border:0; " allowfullscreen=" " loading="lazy "></iframe>


        </div>
        <div fxLayout="column" class="kontakt-main-mobile ">
            <div class="card kontakt "  *ngFor="let contact of contacts">
                <div class="card-body ">
                    <div [innerHTML]="contact.text | safeHtml" class="card-text"></div>
                    <button type="button" class="btn btn-warning" (click)="openModalEdit(contact)" *ngIf="GetActiveUser()">Edytuj</button>
                </div>
            </div>
        </div>
    </div>
</div>
