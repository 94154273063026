import { Component, OnInit } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { Contact } from 'src/app/models/contact.model';
import { AuthService } from 'src/app/services/auth.service';
import { ContactService } from 'src/app/services/contact.service';
import { KontaktModalComponent } from './modal/kontakt-modal/kontakt-modal.component';

@Component({
  selector: 'app-kontakt',
  templateUrl: './kontakt.component.html',
  styleUrls: ['./kontakt.component.sass'],
  providers: [ContactService]
})
export class KontaktComponent implements OnInit {

  modalRef?: MdbModalRef<KontaktModalComponent>;

  public contacts: Contact[] = []

  constructor(private contactService: ContactService,
    private authService: AuthService,
    private modalService: MdbModalService) { }

  ngOnInit(): void {
    this.contactService.getAllContacts().subscribe(data => this.contacts = data)
  }

  GetActiveUser(): boolean{
    if(this.authService.GetActiveUser() !== null) return true
    return false
  }

  navigateToLogout(): void {
    this.authService.SignOut();
  }

  openModalEdit(contactToEdit: Contact) {
    this.modalRef = this.modalService.open(KontaktModalComponent, {
      data: {
        operation: 'Edit',
        contactToEdit: contactToEdit
      },
      backdrop: true,
      ignoreBackdropClick: true
    });
  }

  
  convertToHtml(jsonDoc: string) {
    //const html = toHTML(jsonDoc);
  }

  displayName(){
    let user = this.authService.GetActiveUser();
    return user.displayName;
  }

}
