import { Component, Input, OnInit } from '@angular/core';
import { map } from 'rxjs/internal/operators/map';
import { GalleryFileLoaderService } from 'src/app/services/gallery-file-loader.service';
import { LightgalleryModule } from 'lightgallery/angular';
import lgZoom from 'lightgallery/plugins/zoom';
import { BeforeSlideDetail } from 'lightgallery/lg-events';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@Component({
  selector: 'app-gallery-upload-list',
  templateUrl: './gallery-upload-list.component.html',
  styleUrls: ['./gallery-upload-list.component.sass'],
  providers: [GalleryFileLoaderService]
})
export class GalleryUploadListComponent implements OnInit {

  defaultImage = 'https://miro.medium.com/max/441/1*9EBHIOzhE1XfMYoKz1JcsQ.gif'
  @Input()
  filesPath: string = '';
  @Input()
  show!: boolean
  fileUploads: any[] | undefined;

  constructor(private uploadService: GalleryFileLoaderService) { }
  ngOnInit(): void {
    this.uploadService.getFiles(this.filesPath).snapshotChanges().pipe(
      map(changes =>
        // store the key
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(fileUploads => {
      this.fileUploads = fileUploads
      this.fileUploads = this.fileUploads.filter(file => file.name !== '.ghostfile')
    });
  }



}
