import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestoreDocument, AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SelectedSchool } from '../enum/selected-school';
import { News } from '../models/news.model';
import { SelectSchoolService } from './select-school.service';

@Injectable({
  providedIn: 'root'
})
export class NewsService implements OnDestroy {

  NewsesCollection: AngularFirestoreCollection<News>;
  Newses: Observable<News[]>;
  NewsDoc?: AngularFirestoreDocument<News>;
  selectedSchool: SelectedSchool;
  dbPath: string;

  constructor(private db: AngularFirestore, private selectSchoolService: SelectSchoolService) {
    this.selectedSchool = this.selectSchoolService.getSchool();
    console.log(this.selectedSchool)
    this.dbPath = 'Newses_' + this.selectedSchool;
    this.NewsesCollection = this.db.collection(`${this.dbPath}/`, ref => ref.orderBy('date','desc'));

    this.Newses = this.NewsesCollection.snapshotChanges().pipe(map((changes: any[]) => {
      return changes.map(a => {
        const data = a.payload.doc.data() as News;
        data.id = a.payload.doc.id;
        return data;
      });
    }));
   }

  getAllNewses(){
    return this.Newses;
  }
  addNews(News: News){
    console.log(this.dbPath)
    this.NewsesCollection.add(News);
  }

  deleteNews(News: News){
    this.NewsDoc = this.db.doc(`${this.dbPath}/${News.id}`);
    this.NewsDoc.delete();
  }

  updateNews(News: News, newNews: News){
    console.log(this.dbPath)
    this.NewsDoc = this.db.doc(`${this.dbPath}/${News.id}`);
    this.NewsDoc.update(newNews);
  }

  ngOnDestroy(): void {
  }

}
