import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { News } from 'src/app/models/news.model';
import { NewsService } from 'src/app/services/news.service';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { Editor, Toolbar } from 'ngx-editor';
import { SelectedSchool } from 'src/app/enum/selected-school';
import { SelectSchoolService } from 'src/app/services/select-school.service';

@Component({
  selector: 'app-aktualnosci-modal',
  templateUrl: './aktualnosci-modal.component.html',
  styleUrls: ['./aktualnosci-modal.component.sass'],
  providers: [NewsService]
})
export class AktualnosciModalComponent implements OnInit, OnDestroy {

  public editor_text: Editor;
  public html_text: string = '';
  public toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  public operation?: string;
  public newsToEdit?: News;
  public form: FormGroup = new FormGroup(
    {
      text: new FormControl('', Validators.required),
      priority: new FormControl(false),

    }
  );

  constructor(public modalRef: MdbModalRef<AktualnosciModalComponent>,
    public fb: FormBuilder,
    private newsService: NewsService,) {
      this.editor_text = new Editor();
    }


  ngOnInit(): void {
    if (this.newsToEdit) {
      this.form = new FormGroup(
        {
          text: new FormControl(this.newsToEdit.text || '', Validators.required),
          priority: new FormControl(this.newsToEdit.important || false,),
        }
      )
      this.html_text = this.newsToEdit.text
    }
  }

  get f() {
    return this.form.controls;
  }

  close(): void {
    this.modalRef.close(this.form)
  }

  submit() {
    if (this.form.valid) {
      if (this.operation) {
        if (this.newsToEdit) {
          const newNews = {
            text: this.f.text.value,
            important: this.f.priority.value,
            date: this.newsToEdit.date
          }
          this.newsService.updateNews(this.newsToEdit, newNews)
          this.modalRef.close(this.form);
        }
      }
      else {
        const newNews = {
          text: this.f.text.value,
          important: this.f.priority.value,
          date: new Date().toISOString()
        }
        this.newsService.addNews(newNews)
        this.modalRef.close(this.form);

      }
    }
  }


  operationText(): string {
    if (this.operation) {
      return "Zapisz";
    }
    return "Dodaj";
  }

  ngOnDestroy(): void {
    if(this.editor_text)
    this.editor_text.destroy();
  }
}
