import { Component, OnInit } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { Parent } from 'src/app/models/parents-section.model';
import { AuthService } from 'src/app/services/auth.service';
import { ParentsService } from 'src/app/services/parents-section.service';
import { DlaRodzicowModalComponent } from './modal/dla-rodzicow-modal/dla-rodzicow-modal.component';

@Component({
  selector: 'app-dla-rodzicow',
  templateUrl: './dla-rodzicow.component.html',
  styleUrls: ['./dla-rodzicow.component.sass'],
  providers: [ParentsService]
})
export class DlaRodzicowComponent implements OnInit {


  modalRef?: MdbModalRef<DlaRodzicowModalComponent>;

  public parents: Parent[] = []

  constructor(private parentService: ParentsService, private authService: AuthService, private modalService: MdbModalService) { }

  ngOnInit(): void {
    this.parentService.getAllParents().subscribe(data => this.parents = data)
  }

  GetActiveUser(): boolean{
    if(this.authService.GetActiveUser() !== null) return true
    return false
  }

  navigateToLogout(): void {
    this.authService.SignOut();
  }

  openModalEdit(parentToEdit: Parent) {
    this.modalRef = this.modalService.open(DlaRodzicowModalComponent, {
      data: {
        operation: 'Edit',
        parentToEdit: parentToEdit
      },
      backdrop: true,
      ignoreBackdropClick: true
    });
  }

  displayName(){
    let user = this.authService.GetActiveUser();
    return user.displayName;
  }

  
  convertToHtml(jsonDoc: string) {
    //const html = toHTML(jsonDoc);
  }
}
