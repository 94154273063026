<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Dodaj/Edytuj wiadomość</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">
    <form [formGroup]='form' (ngSubmit)='submit()'>
        <div class="flex-row mb-2">
            <label class="form-label" for="name">Tekst:</label>
            <div class="NgxEditor__Wrapper">
                <ngx-editor-menu [editor]="editor_text" [toolbar]="toolbar"> </ngx-editor-menu>
                <ngx-editor [editor]="editor_text" [ngModel]="html_text" [disabled]="false" [placeholder]="'Wpisz tutaj...'" class="form-control" id="text" formControlName="text"></ngx-editor>
            </div>
        </div>
        <div class="flex-row mb-2">
            <button class="btn btn-success" type="submit" (click)="modalRef.close()">{{operationText()}}</button>
        </div>
    </form>
</div>