<app-menu></app-menu>
<div class="main-admin-menu" *ngIf="GetActiveUser()">
    <div class="admin-menu">
        <h5>Witaj!&nbsp;<strong>{{displayName()}}</strong></h5>
        <button type="button" class="btn btn-success" (click)="openModal()">Dodaj plik</button>
        <button type="button" class="btn btn-info" (click)="navigateToLogout()">Wyloguj</button>
    </div>
</div>
<div class="main">
    <div class="main2">
        <div class="card" *ngFor="let file of pliki">
            <div class="card-body">
                <h5 class="card-title">{{file.title}}</h5>
                <button type="button" class="btn btn-primary" (click)="DownloadFile(file)">Pobierz</button>
                <button type="button" class="btn btn-danger" (click)="deleteFile(file)" *ngIf="GetActiveUser()">Usuń</button>
                <h4 class="card-date">Zamieszczono: {{file.date | date: 'dd/MM/yyyy'}}</h4>
            </div>
        </div>
    </div>
</div>
