import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Info } from '../models/info.model';
import { SelectSchoolService } from './select-school.service';
import { SelectedSchool } from '../enum/selected-school';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InfoService implements OnDestroy {

  InfosCollection: AngularFirestoreCollection<Info>;
  Infos: Observable<Info[]>;
  InfoDoc?: AngularFirestoreDocument<Info>;
  selectedSchool: SelectedSchool;
  dbPath: string;

  constructor(private db: AngularFirestore, private selectSchoolService: SelectSchoolService) { 
    this.selectedSchool = this.selectSchoolService.getSchool();
    this.dbPath = 'Info_' + this.selectedSchool;
    this.InfosCollection = this.db.collection(`${this.dbPath}/`, ref => ref.orderBy('text','desc'));

    this.Infos = this.InfosCollection.snapshotChanges().pipe(map((changes: any[]) => {
      return changes.map(a => {
        const data = a.payload.doc.data() as Info;
        data.id = a.payload.doc.id;
        return data;
      });
    }));
  }

  getAllInfos(){
    return this.Infos;
  }

  addInfo(info: Info){
    this.InfosCollection.add(info);
  }

  deleteInfo(info: Info){
    this.InfoDoc = this.db.doc(`${this.dbPath}/${info.id}`);
    this.InfoDoc.delete();
  }

  updateInfo(info: Info, newInfo: Info){
    this.InfoDoc = this.db.doc(`${this.dbPath}/${info.id}`)
    this.InfoDoc.update(newInfo);
  }

  ngOnDestroy(): void {
  }

}
