import { Injectable, OnDestroy } from '@angular/core';
import { FileDownload } from 'src/app/models/filePost.model';
import { finalize, map } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { FileUpload } from '../models/file-upload.model';
import { SelectedSchool } from '../enum/selected-school';
import { SelectSchoolService } from './select-school.service';
@Injectable({
  providedIn: 'root'
})
export class ContextService implements OnDestroy{

  filesCollection: AngularFirestoreCollection<FileDownload>;
  files: Observable<FileDownload[]>;
  fileDoc?: AngularFirestoreDocument<FileDownload>;
  selectedSchool: SelectedSchool;
  dbPath: string;

  constructor(private db: AngularFirestore, private storage: AngularFireStorage, private selectSchoolService: SelectSchoolService) {
    this.selectedSchool = this.selectSchoolService.getSchool();
    this.dbPath = 'files_' + this.selectedSchool;
    this.filesCollection = this.db.collection(`${this.dbPath}/`, ref => ref.orderBy('date','desc'));

    this.files = this.filesCollection.snapshotChanges().pipe(map((changes: any[]) => {
      return changes.map(a => {
        const data = a.payload.doc.data() as FileDownload;
        data.id = a.payload.doc.id;
        return data;
      });
    }));
   }

   getAllFiles(){
    return this.files;
  }
  addFile(file: FileDownload){
    this.filesCollection.add(file);
  }

  deleteFilePost(file: FileDownload){
    this.fileDoc = this.db.doc(`${this.dbPath}/${file.id}`);
    this.fileDoc.delete()
    .then(() => {
      this.deleteFileStorage(file.fileName);
    });
  }
  updateFile(file: FileDownload, newFile: FileDownload){
    this.fileDoc = this.db.doc(`${this.dbPath}/${file.id}`);
    this.fileDoc.update(newFile);
  }
  pushFileToStorage(fileUpload: FileUpload): Observable<number | undefined> {
    const filePath = `${this.dbPath}/${fileUpload.file.name}`;
    const storageRef = this.storage.ref(filePath);
    const uploadTask = this.storage.upload(filePath, fileUpload.file);

    uploadTask.snapshotChanges().pipe(
      finalize(() => {
        storageRef.getDownloadURL().subscribe(downloadURL => {
          fileUpload.url = downloadURL;
          fileUpload.name = fileUpload.file.name;
        });
      })
    ).subscribe();

    return uploadTask.percentageChanges();
  }

  getFile(file: FileDownload){
    return this.storage.ref(`${this.dbPath}/${file.id}`)
  }


  private deleteFileStorage(name: string): void {
    const storageRef = this.storage.ref(`${this.dbPath}/`);
    storageRef.child(name).delete();
  }

  ngOnDestroy(): void {
  }

}
