import { Component, OnInit } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { Info } from 'src/app/models/info.model';
import { AuthService } from 'src/app/services/auth.service';
import { InfoService } from 'src/app/services/info.service';
import { ONasModalComponent } from './modal/o-nas-modal/o-nas-modal.component';
import { toHTML } from 'ngx-editor';

@Component({
  selector: 'app-o-nas',
  templateUrl: './o-nas.component.html',
  styleUrls: ['./o-nas.component.sass'],
  providers: [InfoService]
})
export class ONasComponent implements OnInit {


  modalRef?: MdbModalRef<ONasModalComponent>;

  public infos: Info[] = []

  constructor(
      private infoService: InfoService,
      private authService: AuthService,
      private modalService: MdbModalService,) { }

  ngOnInit(): void {
    this.infoService.getAllInfos().subscribe(data => this.infos = data)
  }

  GetActiveUser(): boolean{
    if(this.authService.GetActiveUser() !== null) return true
    return false
  }

  navigateToLogout(): void {
    this.authService.SignOut();
  }


  openModalEdit(infoToEdit: Info) {
    this.modalRef = this.modalService.open(ONasModalComponent, {
      data: {
        operation: 'Edit',
        infoToEdit: infoToEdit
      },
      backdrop: true,
      ignoreBackdropClick: true

    });
  }

  displayName(){
    let user = this.authService.GetActiveUser();
    return user.displayName;
  }



  convertToHtml(jsonDoc: string) {
    //const html = toHTML(jsonDoc);
  }
}
