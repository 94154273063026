<div fxHide.sm fxHide.xs class="body">
    <div class="logo">
        <img src="assets/images/napis.webp">
    </div>


    <div class="main">
        <div fxLayout="row">
            <div fxLayout="column" fxFlex="50%">
              <a [routerLink]="['/redirect', 'matejki']"><img src="assets/images/ulmatejki.webp"></a>
            </div>
            <div fxLayout="column" fxFlex="50%">
                <a [routerLink]="['/redirect', 'krakowska']"><img src="assets/images/ulkrakowska.webp"></a>
            </div>
        </div>
    </div>
</div>



<!--Mobile-->

<div fxHide.gt-sm class="main-mobile">
    <div fxLayout="column">
        <img src="assets/images/napis.webp">
        <a [routerLink]="['/redirect', 'matejki']"><img src="assets/images/ulmatejki.webp"></a>
    </div>
    <div fxLayout="row">
        <a [routerLink]="['/redirect', 'krakowska']"><img src="assets/images/ulkrakowska.webp"></a>
    </div>
</div>

