import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestoreDocument, AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SelectedSchool } from '../enum/selected-school';
import { Parent } from '../models/parents-section.model';
import { SelectSchoolService } from './select-school.service';

@Injectable({
  providedIn: 'root'
})
export class ParentsService implements OnDestroy {

  ParentsCollection: AngularFirestoreCollection<Parent>;
  Parents: Observable<Parent[]>;
  ParentDoc?: AngularFirestoreDocument<Parent>;
  selectedSchool: SelectedSchool;
  dbPath: string;

  constructor(private db: AngularFirestore, 
    private selectSchoolService: SelectSchoolService) { 
      
    this.selectedSchool = this.selectSchoolService.getSchool();
    this.dbPath = 'Parents_' + this.selectedSchool;
    this.ParentsCollection = this.db.collection(`${this.dbPath}/`, ref => ref.orderBy('date','desc'));

    this.Parents = this.ParentsCollection.snapshotChanges().pipe(map((changes: any[]) => {
      return changes.map(a => {
        const data = a.payload.doc.data() as Parent;
        data.id = a.payload.doc.id;
        return data;
      });
    }));
  }

  getAllParents(){
    return this.Parents;
  }

  addParent(parent: Parent){
    this.ParentsCollection.add(parent);
  }

  deleteParent(parent: Parent){
    this.ParentDoc = this.db.doc(`${this.dbPath}/${parent.id}`);
    this.ParentDoc.delete();
  }

  updateParent(parent: Parent, newParent: Parent){
    this.ParentDoc = this.db.doc(`${this.dbPath}/${parent.id}`)
    this.ParentDoc.update(newParent);
  }

  ngOnDestroy(): void {
  }

}