import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { get } from 'http';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { Validators } from 'ngx-editor';
import { map } from 'rxjs/operators';
import { SelectedSchool } from 'src/app/enum/selected-school';
import { FileUpload } from 'src/app/models/file-upload.model';
import { DoPobraniaModalComponent } from 'src/app/pages/do-pobrania/modal/do-pobrania-modal/do-pobrania-modal.component';
import { ContextService } from 'src/app/services/context.service';
import { GalleryFileLoaderService } from 'src/app/services/gallery-file-loader.service';
import { SelectSchoolService } from 'src/app/services/select-school.service';

@Component({
  selector: 'app-galeria-modal',
  templateUrl: './galeria-modal.component.html',
  styleUrls: ['./galeria-modal.component.sass'],
})
export class GaleriaModalComponent implements OnInit {
  public operation?: string;
  folderName?: string;
  selectedFiles?: FileList;
  currentFileUpload?: FileUpload;
  percentage = 0;
  fileUploads: any[] = [];
  selectedSchool: SelectedSchool;
  dbPath: string;
  filesPath?: string;
  public form: FormGroup = new FormGroup({
    folder: new FormControl(''),
  });

  constructor(
    public modalRef: MdbModalRef<GaleriaModalComponent>,
    public fb: FormBuilder,
    private filesService: GalleryFileLoaderService,
    private selectSchoolService: SelectSchoolService
  ) {
    this.selectedSchool = this.selectSchoolService.getSchool();
    this.dbPath = 'gallery_' + this.selectedSchool;
  }
  ngOnInit(): void {
    if (this.operation) {
      this.form = new FormGroup({
        folder: new FormControl(this.folderName || ''),
      });
    }
  }

  get f() {
    return this.form.controls;
  }

  close(): void {
    this.modalRef.close(this.form);
  }

  addFolderOperation(): boolean {
    if (this.operation == 'Folder') return true;
    return false;
  }

  submit() {
    if (this.operation) {
      const path = this.dbPath + '/' + this.f.folder.value;
      this.filesService.createFolder(path);
    }
  }
  selectFile(event: any): void {
    this.selectedFiles = event.target.files;
  }
  deleteFileUpload(fileUpload: FileUpload): void {
    if (this.filesPath) {
    this.filesService.deleteFile(fileUpload, this.filesPath);
    }
  }

  upload(): void {
    if (this.filesPath) {
      if (this.selectedFiles) {
        const file: File | null = this.selectedFiles.item(0);
        this.selectedFiles = undefined;

        if (file) {
          this.currentFileUpload = new FileUpload(file);
          this.filesService
            .pushFileToStorage(this.currentFileUpload, this.filesPath)
            .subscribe(
              (percentage) => {
                this.percentage = Math.round(percentage ? percentage : 0);
              },
              (error: any) => {
                console.log(error);
              }
            );
        }
      }
    }
  }
}
