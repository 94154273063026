import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { FileUpload } from 'src/app/models/file-upload.model';
import { FileDownload } from 'src/app/models/filePost.model';
import { ContextService } from 'src/app/services/context.service';

@Component({
  selector: 'app-do-pobrania-modal',
  templateUrl: './do-pobrania-modal.component.html',
  styleUrls: ['./do-pobrania-modal.component.sass'],
  providers: [ContextService]
})
export class DoPobraniaModalComponent implements OnInit {
  public operation?: string;
  public fileToEdit?: FileDownload;
  selectedFiles?: FileList;
  currentFileUpload?: FileUpload;
  percentage = 0;
  public form: FormGroup = new FormGroup(
    {
      title: new FormControl('', Validators.required),
    }
  );

  constructor(public modalRef: MdbModalRef<DoPobraniaModalComponent>, public fb: FormBuilder, private filesService: ContextService) { }

  ngOnInit(): void {
    if(this.fileToEdit) {
      this.form = new FormGroup(
        {
          title: new FormControl(this.fileToEdit.title || '', Validators.required),
        }
      )
    }
  }

  get f() {
    return this.form.controls;
  }

  close(): void {
    this.modalRef.close(this.form)
  }

  submit() {
    if (this.form.valid) {
      if (this.operation) {
        if (this.fileToEdit) {
          const newFile = {
            title: this.f.title.value,
            fileName: this.fileToEdit.fileName,
            date: this.fileToEdit.date
          }
          this.filesService.updateFile(this.fileToEdit, newFile)
            this.modalRef.close(this.form);
          }
        }
      else {
        if(this.currentFileUpload){
          const newFile = {
            title: this.f.title.value,
            fileName: this.currentFileUpload.name,
            date: new Date().toISOString()
          }
          this.filesService.addFile(newFile)
            this.modalRef.close(this.form);
        }
      }
    }
  }
  selectFile(event: any): void {
    this.selectedFiles = event.target.files;
  }

  upload(): void {
    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);
      this.selectedFiles = undefined;

      if (file) {
        this.currentFileUpload = new FileUpload(file);
        this.filesService.pushFileToStorage(this.currentFileUpload).subscribe(
          percentage => {
            this.percentage = Math.round(percentage ? percentage : 0);
          },
          error => {
            console.log(error);
          }
        );
      }
    }
  }

  operationText(): string {
    if (this.operation) {
      return "Zapisz";
    }
    return "Dodaj";
  }
}
