import { Component, OnInit } from '@angular/core';
import { TestBed } from '@angular/core/testing';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { FileDownload } from 'src/app/models/filePost.model';
import { ContextService } from 'src/app/services/context.service';
import { DoPobraniaModalComponent } from './modal/do-pobrania-modal/do-pobrania-modal.component';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { AuthService } from 'src/app/services/auth.service';
import { SelectedSchool } from 'src/app/enum/selected-school';
import { SelectSchoolService } from 'src/app/services/select-school.service';

@Component({
  selector: 'app-do-pobrania',
  templateUrl: './do-pobrania.component.html',
  styleUrls: ['./do-pobrania.component.sass'],
  providers: [ContextService]
})
export class DoPobraniaComponent implements OnInit {

  modalRef?: MdbModalRef<DoPobraniaModalComponent>;
  selectedSchool: SelectedSchool;
  dbPath: string;
  public pliki: FileDownload[] = [];

  constructor(private modalService: MdbModalService,
    private db: ContextService, private authService: AuthService,
    private selectSchoolService: SelectSchoolService) {
    this.selectedSchool = this.selectSchoolService.getSchool();
    this.dbPath = 'files_' + this.selectedSchool; }




  ngOnInit(): void {
    this.db.getAllFiles().subscribe((res: any) =>this.pliki = res);
  }

  deleteFile(file: FileDownload): void {
    this.db.deleteFilePost(file)
  }

  navigateToLogout(): void {
    this.authService.SignOut();
  }


  openModalEdit(fileToEdit: FileDownload) {
    this.modalRef = this.modalService.open(DoPobraniaModalComponent, {
      data: {
        operation: 'Edit',
        fileToEdit: fileToEdit
      }
    });
  }

  GetActiveUser(): boolean{
    if(this.authService.GetActiveUser() !== null) return true
    return false
  }
  openModal() {
    this.modalRef = this.modalService.open(DoPobraniaModalComponent);
  }
    DownloadFile(file: FileDownload){
    const storage = getStorage();
    const fileRef = this.db.getFile(file);
    getDownloadURL(ref(storage, `${this.dbPath}/${file.fileName}`))
    .then((url) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = function() {
        var a = document.createElement('a');
        a.href = window.URL.createObjectURL(xhr.response);
        a.download = file.fileName; // Name the file anything you'd like.
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
      };
      xhr.open('GET', url);
      xhr.send();
    })
  }

  displayName(){
    let user = this.authService.GetActiveUser();
    return user.displayName;
  }

}
