<div class="card" *ngIf="!show">
    <div>
        <div class=" card-header ">Wszystkie zdjęcia</div>
        <ul class="list-group list-group-flush " *ngFor="let file of fileUploads ">
            <li class="list-group-item ">
                <app-gallery-upload-details [fileUpload]='file' [filesPath]='filesPath'></app-gallery-upload-details>
            </li>
        </ul>
    </div>
</div>
<div class="main" *ngIf="show">
    <div class="row">
        <div class="col-sm-2 justify-center" style="margin: auto;" *ngFor="let file of fileUploads">
            <div class="image">
                <img [lazyLoad]="file.url" [defaultImage]="defaultImage">
            </div>
        </div>
    </div>
</div>
