<div class="body">
    <div fxHide.sm fxHide.xs>
        <ul>
            <div class="container box">
                <a routerLink="/"><img src="/assets/images/house.webp"></a>
                <div class="row justify-content-between flex-nowrap margins">
                    <div class="logo-container">
                        <a routerLink="/aktualnosci" class="logo"></a>
                        <div class="name">
                            <h1>ul.{{selectedSchool}}</h1>
                        </div>
                    </div>
                    <a routerLink="/ukraina" class="cloud" *ngIf="isKrakowska()">
                        <p>Україна</p>
                    </a>
                    <a routerLink="/o-nas" class="cloud">
                        <p>O nas</p>
                    </a>
                    <a routerLink="/dla-rodzicow" class="cloud">
                        <p>Dla rodziców</p>
                    </a>
                    <a routerLink="/do-pobrania" class="cloud">
                        <p>Do pobrania</p>
                    </a>
                    <a routerLink="/galeria" class="cloud">
                        <p>Galeria</p>
                    </a>
                    <a routerLink="/kontakt" class="cloud">
                        <p>Kontakt</p>
                    </a>
                    <a routerLink="/login" class="cloud" *ngIf="!GetActiveUser()">
                        <p>Logowanie</p>
                    </a>
                </div>
            </div>
        </ul>
    </div>
</div>


<!--Mobile-->

<div fxLayout="row" fxHide.gt-sm class="container-mobile">
    <div fxFlex="15" class="button-menu">
        <button mat-button [matMenuTriggerFor]="menu" class="menu-icon">
      <mat-icon>menu</mat-icon>
    </button>
        <mat-menu #menu="matMenu">
            <a mat-menu-item routerLink="/ukraina">Україна</a>
            <a mat-menu-item routerLink="/aktualnosci">Aktualności</a>
            <a mat-menu-item routerLink="/o-nas">O nas</a>
            <a mat-menu-item routerLink="/dla-rodzicow">Dla rodziców</a>
            <a mat-menu-item routerLink="/do-pobrania">Do pobrania</a>
            <a mat-menu-item routerLink="/galeria">Galeria</a>
            <a mat-menu-item routerLink="/kontakt">Kontakt</a>
            <a mat-menu-item routerLink="/">Zmień Przedszkole</a>
            <a mat-menu-item routerLink="/login" *ngIf="!GetActiveUser()">Logowanie</a>
        </mat-menu>
    </div>
    <div fxFlex="85">
        <h1>ul.{{selectedSchool}}</h1>
    </div>

</div>