<app-menu></app-menu>

<div class="main-admin-menu" *ngIf="GetActiveUser()">
    <div class="admin-menu">
        <h5>Witaj!&nbsp;<strong>{{displayName()}}</strong></h5>
        <button type="button" class="btn btn-info" (click)="navigateToLogout()">Wyloguj</button>
    </div>
</div>

<div class="main">
    <div class="card main-card">
        <div class="card-body" *ngFor="let parent of parents">
            <div [innerHTML]="parent.text | safeHtml" class="card-text"></div>
            <button type="button" class="btn btn-warning" (click)="openModalEdit(parent)" *ngIf="GetActiveUser()">Edytuj</button>
        </div>
    </div>
</div>