import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SelectSchoolService } from '../services/select-school.service';

@Injectable({
  providedIn: 'root'
})
export class HasSelectedSchoolGuard implements CanActivate {

  constructor (private router: Router, private selectSchoolService: SelectSchoolService) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
    const selectedSchool = this.selectSchoolService.getSchool()
    if(selectedSchool !== null) return true;
    this.router.navigateByUrl('');
    return false
  }

}
