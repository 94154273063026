import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './menu/menu.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ONasComponent } from './pages/o-nas/o-nas.component';
import { DlaRodzicowComponent } from './pages/dla-rodzicow/dla-rodzicow.component';
import { DoPobraniaComponent } from './pages/do-pobrania/do-pobrania.component';
import { GaleriaComponent } from './pages/galeria/galeria.component';
import { KontaktComponent } from './pages/kontakt/kontakt.component';
import { MatMenuModule } from '@angular/material/menu';
import { AktualnosciComponent } from './pages/aktualnosci/aktualnosci.component';
import { MatIconModule } from '@angular/material/icon';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { environment } from '../environments/environment';
import {  MdbModalModule } from 'mdb-angular-ui-kit/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DoPobraniaModalComponent } from './pages/do-pobrania/modal/do-pobrania-modal/do-pobrania-modal.component';
import { HttpClientModule } from '@angular/common/http';
import { AktualnosciModalComponent } from './pages/aktualnosci/modal/aktualnosci-modal/aktualnosci-modal.component';
import { StronaGlownaComponent } from './pages/strona-glowna/strona-glowna.component';
import { LoginComponent } from './pages/login/login.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { ONasModalComponent } from './pages/o-nas/modal/o-nas-modal/o-nas-modal.component';
import { DlaRodzicowModalComponent } from './pages/dla-rodzicow/modal/dla-rodzicow-modal/dla-rodzicow-modal.component';
import { NgxEditorModule } from 'ngx-editor';
import { SafeHtmlPipe } from './safe-html.pipe';
import { KontaktModalComponent } from './pages/kontakt/modal/kontakt-modal/kontakt-modal.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { RedirectComponent } from './pages/redirect/redirect.component';
import { GaleriaModalComponent } from './pages/galeria/modal/galeria-modal/galeria-modal.component';
import { GalleryUploadListComponent } from './pages/galeria/upload-list/gallery-upload-list/gallery-upload-list.component';
import { GalleryUploadDetailsComponent } from './pages/galeria/upload-details/gallery-upload-details/gallery-upload-details.component';
import { SwiperModule } from 'swiper/angular';
import { UkrainaComponent } from './pages/ukraina/ukraina.component';
import { UkrainaModalComponent } from './pages/ukraina/modal/ukraina-modal/ukraina-modal.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';


@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    ONasComponent,
    KontaktComponent,
    GaleriaComponent,
    DlaRodzicowComponent,
    DoPobraniaComponent,
    AktualnosciComponent,
    DoPobraniaModalComponent,
    AktualnosciModalComponent,
    StronaGlownaComponent,
    LoginComponent,
    ONasModalComponent,
    DlaRodzicowModalComponent,
    SafeHtmlPipe,
    RedirectComponent,
    KontaktModalComponent,
    GaleriaModalComponent,
    GalleryUploadListComponent,
    GalleryUploadDetailsComponent,
    UkrainaComponent,
    UkrainaModalComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatIconModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    MdbModalModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgxEditorModule,
    MatSlideToggleModule,
    SwiperModule,
    LazyLoadImageModule,

  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
