import { Component, OnInit } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { map } from 'rxjs/internal/operators/map';
import { take } from 'rxjs/internal/operators/take';
import { SelectedSchool } from 'src/app/enum/selected-school';
import { FileUpload } from 'src/app/models/file-upload.model';
import { GalleryFileLoaderService } from 'src/app/services/gallery-file-loader.service';
import { SelectSchoolService } from 'src/app/services/select-school.service';
import { GaleriaModalComponent } from './modal/galeria-modal/galeria-modal.component';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-galeria',
  templateUrl: './galeria.component.html',
  styleUrls: ['./galeria.component.sass'],
  providers: [GalleryFileLoaderService]
})
export class GaleriaComponent implements OnInit {

  modalRef?: MdbModalRef<GaleriaModalComponent>;
  selectedSchool: SelectedSchool;
  dbPath: string;
  foldersList: any[]= [];
  selectedFolder?: string
  constructor(private modalService: MdbModalService,
    private selectSchoolService: SelectSchoolService,
    private filesService: GalleryFileLoaderService,
    private authService: AuthService) {
    this.selectedSchool = this.selectSchoolService.getSchool();
    this.dbPath = 'gallery_' + this.selectedSchool;
  }

  ngOnInit(): void {
    this.refreshFolders()
  }
  buildFilePath() {
    return this.dbPath + '/' + this.selectedFolder
  }

  openModal() {
    this.modalRef = this.modalService.open(GaleriaModalComponent, {
      data: {
        filesPath: this.dbPath + '/' + this.selectedFolder
      }
    });
  }
  openModalAddFolder() {
    this.modalRef = this.modalService.open(GaleriaModalComponent, {
      data: {
        operation: 'Folder'
      }
    });
    this.modalRef.onClose.subscribe(data =>
     this.refreshFolders() )
  }

  refreshFolders(){
    this.filesService.getAllFolders().snapshotChanges().pipe(
      map(changes =>
        changes.map(c => ({name: c.payload.key})
      )
    )
    ).subscribe(data => this.foldersList = data)
  }

  selectFolder(folder: string){
    this.selectedFolder = folder
  }

  clearFolder(){
    this.selectedFolder = undefined
  }

  deleteFolder(folderName: string){
    const path =  this.dbPath + '/' + folderName
    this.filesService.getFiles(path).snapshotChanges().pipe(
      take(1),
       map(changes =>
        changes.map(c =>
          ({ key: c.payload.key, ...c.payload.val() } as FileUpload)
        )
      )
    ).subscribe(x => this.filesService.deleteFile(x[0], path))
  }

  GetActiveUser(): boolean{
    if(this.authService.GetActiveUser() !== null) return true
    return false
  }

  navigateToLogout(): void {
    this.authService.SignOut();
  }

  displayName(){
    let user = this.authService.GetActiveUser();
    return user.displayName;
  }
}
