import { Component, OnInit } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { News } from 'src/app/models/news.model';
import { AuthService } from 'src/app/services/auth.service';
import { UkrainaService } from 'src/app/services/ukraina.service';
import { UkrainaModalComponent } from './modal/ukraina-modal/ukraina-modal.component';

@Component({
  selector: 'app-ukraina',
  templateUrl: './ukraina.component.html',
  styleUrls: ['./ukraina.component.sass'],
  providers: [UkrainaService]
})
export class UkrainaComponent implements OnInit {

  modalRef?: MdbModalRef<UkrainaModalComponent>;

  public newses: News[] = []

  constructor(
    private newsService: UkrainaService,
    private modalService: MdbModalService,
    private authService: AuthService) {
    }

  ngOnInit(): void {
    this.newsService.getAllNewses().subscribe((data: any[]) => {
      let priorityNewses = data.filter(x => x.important == true)
      const notPriorityNewses = data.filter(x => x.important == false)
      this.newses = priorityNewses.concat(notPriorityNewses)
    })
  }

  openModalEdit(newsToEdit: News) {
    this.modalRef = this.modalService.open(UkrainaModalComponent, {
      data: {
        operation: 'Edit',
        newsToEdit: newsToEdit
      },
      backdrop: true,
      ignoreBackdropClick: true
    });
  }
  GetActiveUser(): boolean{
    if(this.authService.GetActiveUser() !== null) return true
    return false
  }

  openModal() {
    this.modalRef = this.modalService.open(UkrainaModalComponent, {
      backdrop: true,
      ignoreBackdropClick: true
    });
  }

  deleteNews(News: News): void{
    this.newsService.deleteNews(News)
  }

  navigateToLogout(): void {
    this.authService.SignOut();
  }

  displayName(){
    let user = this.authService.GetActiveUser();
    return user.displayName;
  }

}
