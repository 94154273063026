<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel" *ngIf="addFolderOperation()">Dodaj/Edytuj Folder</h5>
    <h5 class="modal-title" id="exampleModalLabel" *ngIf="!addFolderOperation()">Dodaj/Edytuj Zdjecia</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">
    <div *ngIf="!addFolderOperation()">
        <div class="flex-row mb-2">
            <label>
        <input type="file" class="text-nowrap text-truncate" (change)="selectFile($event)" />
      </label>

            <button class="btn btn-success btn-sm" [disabled]="!selectedFiles" (click)="upload()">
        Wyślij
      </button>

            <div *ngIf="currentFileUpload" class="progress mt-2">
                <div class="progress-bar progress-bar-info" role="progressbar" attr.aria-valuenow="{{ percentage }}" aria-valuemin="0" aria-valuemax="100" [ngStyle]="{ width: percentage + '%' }">
                    {{ percentage }}%
                </div>
            </div>
        </div>
    </div>
    <form [formGroup]='form' (ngSubmit)='submit()' *ngIf="addFolderOperation()">
        <div class="flex-row mb-2">
            <label class="form-label" for="name">Nazwa folderu:</label>
            <input type="text" class="form-control" id="folder" formControlName="folder">
        </div>
        <div class="flex-row mb-2">
            <button class="btn btn-success" type="submit" (click)="modalRef.close()">Dodaj</button>
        </div>
    </form>
</div>
<app-gallery-upload-list *ngIf="!addFolderOperation() && filesPath" [filesPath]='filesPath' [show]='false'></app-gallery-upload-list>