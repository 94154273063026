import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/compat/database';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SelectedSchool } from '../enum/selected-school';
import { FileUpload } from '../models/file-upload.model';
import { SelectSchoolService } from './select-school.service';

@Injectable({
  providedIn: 'root'
})
export class GalleryFileLoaderService {

  selectedSchool: SelectedSchool;
  basePath: string;
constructor(private db: AngularFireDatabase,
   private storage: AngularFireStorage,
      private selectSchoolService: SelectSchoolService
  ) {
    this.selectedSchool = this.selectSchoolService.getSchool();
    this.basePath = 'gallery_' + this.selectedSchool;

 }
pushFileToStorage(fileUpload: FileUpload, path: string): Observable<number | undefined> {
  const filePath = `${path}/${fileUpload.file.name}`;
  const storageRef = this.storage.ref(filePath);
  const uploadTask = this.storage.upload(filePath, fileUpload.file);
  uploadTask.snapshotChanges().pipe(
    finalize(() => {
      storageRef.getDownloadURL().subscribe(downloadURL => {
        fileUpload.url = downloadURL;
        fileUpload.name = fileUpload.file.name;
        this.saveFileData(fileUpload, path);
      });
    })
  ).subscribe();
  return uploadTask.percentageChanges();
}
  private saveFileData(fileUpload: FileUpload, path: string): void {
    this.db.list(path).push(fileUpload);
  }
  getFiles(path: string): AngularFireList<FileUpload> {
    return this.db.list(path);
  }
  deleteFile(fileUpload: FileUpload, path: string): void {
    this.deleteFileDatabase(fileUpload.key, path)
      .then(() => {
        this.deleteFileStorage(fileUpload.name, path);
      })
      .catch(error => console.log(error));
  }
  private deleteFileDatabase(key: string, path: string): Promise<void> {
    return this.db.list(path).remove(key);
  }
  private deleteFileStorage(name: string, path: string): void {
    const storageRef = this.storage.ref(path);
    storageRef.child(name).delete();
  }

  public createFolder(path: string) {
    const file = {
      name: '.ghostfile'
    }
    this.storage.upload(`${path}/.ghostfile`, '.ghostfile')
    this.db.list(path).push(file);
  }

  public removeFolder(path: string) {
    this.storage.ref(path).delete()
  }

  public getFolders(){
    return this.storage.ref(`${this.basePath}/`).listAll()
  }

  public getAllFolders(){
    return this.db.list(this.basePath)
  }
}
