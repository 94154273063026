<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Dodaj/Edytuj plik</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">
    <form [formGroup]='form' (ngSubmit)='submit()'>
        <div class="flex-row mb-2">
            <label class="form-label" for="name">Nazwa pliku:</label>
            <input type="text" class="form-control" id="title" formControlName="title">
        </div>
        <div class="flex-row mb-2">
            <label>
        <input type="file" class="text-nowrap text-truncate" (change)="selectFile($event)" />
      </label>

            <button class="btn btn-success btn-sm" [disabled]="!selectedFiles" (click)="upload()">
        Wyślij
      </button>

            <div *ngIf="currentFileUpload" class="progress mt-2">
                <div class="progress-bar progress-bar-info" role="progressbar" attr.aria-valuenow="{{ percentage }}" aria-valuemin="0" aria-valuemax="100" [ngStyle]="{ width: percentage + '%' }">
                    {{ percentage }}%
                </div>
            </div>
        </div>
        <div class="flex-row mb-2">
            <button class="btn btn-success" type="submit" (click)="modalRef.close()">{{operationText()}}</button>
        </div>
    </form>
</div>