import { Component, OnInit } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { News } from 'src/app/models/news.model';
import { AuthService } from 'src/app/services/auth.service';
import { NewsService } from 'src/app/services/news.service';
import { AktualnosciModalComponent } from './modal/aktualnosci-modal/aktualnosci-modal.component';

@Component({
  selector: 'app-aktualnosci',
  templateUrl: './aktualnosci.component.html',
  styleUrls: ['./aktualnosci.component.sass'],
  providers: [NewsService],
})
export class AktualnosciComponent implements OnInit {

  modalRef?: MdbModalRef<AktualnosciModalComponent>;

  public newses: News[] = []

  constructor(
    private newsService: NewsService,
    private modalService: MdbModalService,
    private authService: AuthService) {
    }

  ngOnInit(): void {
    this.newsService.getAllNewses().subscribe(data => {
      let priorityNewses = data.filter(x => x.important == true)
      const notPriorityNewses = data.filter(x => x.important == false)
      this.newses = priorityNewses.concat(notPriorityNewses)
    })
  }

  openModalEdit(newsToEdit: News) {
    this.modalRef = this.modalService.open(AktualnosciModalComponent, {
      data: {
        operation: 'Edit',
        newsToEdit: newsToEdit
      },
      backdrop: true,
      ignoreBackdropClick: true
    });
  }
  GetActiveUser(): boolean{
    if(this.authService.GetActiveUser() !== null) return true
    return false
  }

  openModal() {
    this.modalRef = this.modalService.open(AktualnosciModalComponent, {
      backdrop: true,
      ignoreBackdropClick: true
    });
  }

  deleteNews(News: News): void{
    this.newsService.deleteNews(News)
  }

  navigateToLogout(): void {
    this.authService.SignOut();
  }

  displayName(){
    let user = this.authService.GetActiveUser();
    return user.displayName;
  }

}
