import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { Info } from 'src/app/models/info.model';
import { AktualnosciModalComponent } from 'src/app/pages/aktualnosci/modal/aktualnosci-modal/aktualnosci-modal.component';
import { InfoService } from 'src/app/services/info.service';
import { Editor, schema, Toolbar } from 'ngx-editor';

@Component({
  selector: 'app-o-nas-modal',
  templateUrl: './o-nas-modal.component.html',
  styleUrls: ['./o-nas-modal.component.sass'],
  providers: [InfoService]
})
export class ONasModalComponent implements OnInit, OnDestroy {

  public editor_text: Editor;
  public html_text: string = '';
  public toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  public operation?: string;
  public infoToEdit?: Info;
  public form: FormGroup = new FormGroup(
    {
      text: new FormControl('', Validators.required),
    }
  );

  constructor(public modalRef: MdbModalRef<ONasModalComponent>, public fb: FormBuilder, private infoService: InfoService) {
    this.editor_text = new Editor();
   }

  ngOnInit(): void {

    if (this.infoToEdit) {
      this.form = new FormGroup(
        {
          text: new FormControl(this.infoToEdit.text || '', Validators.required),
        }
      )
      this.html_text = this.infoToEdit.text
    }
  }

  get f() {
    return this.form.controls;
  }

  close(): void {
    this.modalRef.close(this.form)
  }

  submit() {
    if (this.form.valid) {
      if (this.operation) {
        if (this.infoToEdit) {
          const newInfo = {
            text: this.f.text.value,
            date: this.infoToEdit.date
          }
          this.infoService.updateInfo(this.infoToEdit, newInfo)
          this.modalRef.close(this.form);
        }
      }
      else {

        const newInfo = {
          text: this.f.text.value,
          date: new Date().toISOString()
        }
        this.infoService.addInfo(newInfo)
        this.modalRef.close(this.form);

      }
    }
  }

  operationText(): string {
    if (this.operation) {
      return "Zapisz";
    }
    return "Dodaj";
  }
  ngOnDestroy(): void {
    if(this.editor_text)
    this.editor_text.destroy();
  }



}
