export const environment = {
  production: false,
  firebaseConfig : {
    apiKey: "AIzaSyBBABDm1tghHvABO3D8aJDAxOejcRsAu-A",
    authDomain: "przedszkole-b1216.firebaseapp.com",
    projectId: "przedszkole-b1216",
    databaseURL: "https://przedszkole-b1216-default-rtdb.europe-west1.firebasedatabase.app",
    storageBucket: "przedszkole-b1216.appspot.com",
    messagingSenderId: "644340370749",
    appId: "1:644340370749:web:9271d49fb09c77c146c954",
    measurementId: "G-GDYEEDXEHC"
  }
};
