<app-menu></app-menu>
<div class="user-panel" *ngIf="!GetActiveUser()">
    <button class="btn btn-danger" (click)="clearFolder()" *ngIf="selectedFolder">Wróć!</button>
</div>
<div class="main-admin-menu" *ngIf="GetActiveUser()">
    <div class="admin-menu">
        <h5>Witaj!&nbsp;<strong>{{displayName()}}</strong></h5>
        <button class="btn btn-danger" (click)="openModalAddFolder()" *ngIf="!selectedFolder">Dodaj Folder</button>
        <button type="button" class="btn btn-info" (click)="navigateToLogout()">Wyloguj</button>
        <button class="btn btn-danger" (click)="openModal()" *ngIf="selectedFolder">Dodaj Zdjęcia</button>
        <button class="btn btn-danger" (click)="clearFolder()" *ngIf="selectedFolder">Wróć!</button>
    </div>
</div>


<div class="main" *ngIf="!selectedFolder">
    <div class="row">
        <div class="col-sm-3 justify-center" style="margin: auto;" *ngFor="let folder of foldersList">
            <div class="card">
                <div class="card-body">
                    <h1 class="card-title">{{folder.name}}</h1>
                    <button class="btn btn-success" (click)="selectFolder(folder.name)">Wejdź</button>
                    <button class="btn btn-warning" (click)="deleteFolder(folder.name)" *ngIf=" GetActiveUser() ">Usun</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div>
    <app-gallery-upload-list *ngIf="selectedFolder" [filesPath]='buildFilePath()' [show]='true'></app-gallery-upload-list>
</div>
