<div class="main">
    <div class="card">
        <div class="card-body">
            <div class="displayTable" *ngIf="!GetActiveUser()">
                <div class="displayTableCell">
                    <div class="authBlock">
                        <h3>Logowanie</h3>
                        <div class="formGroup">
                            <mat-form-field appearance="outline">
                                <mat-label>Login</mat-label>
                                <input matInput type="text" class="formControl" placeholder="Login" #userName required>
                            </mat-form-field>
                        </div>
                        <div class="formGroup">
                            <mat-form-field appearance="outline">
                                <mat-label>Hasło</mat-label>
                                <input matInput type="password" class="formControl" placeholder="Hasło" #userPassword required>
                            </mat-form-field>

                        </div>
                        <!-- Calling SignIn Api from AuthService -->
                        <div class="formGroup">
                            <input type="button" class="btn btn-success" value="Zaloguj się" (click)="authService.SignIn(userName.value, userPassword.value)">
                            <button class="btn btn-danger button-wyjdz" (click)="navigateToChoice()">Wyjdz</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="displayTable" *ngIf="GetActiveUser()">
                <div class="displayTableCell">
                    <div class="authBlock">
                        <h3>Wyloguj</h3>
                        <input type="button" class="btn btn-primary" value="Wyloguj" (click)="authService.SignOut()">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
