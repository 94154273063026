<app-menu class="menu-body">
</app-menu>

<div class="main-admin-menu" *ngIf="GetActiveUser()">
    <div class="admin-menu">
        <h5>Witaj!&nbsp;<strong>{{displayName()}}</strong></h5>
        <button type="button" class="btn btn-success" (click)="openModal()">Dodaj wiadomość!</button>
        <button type="button" class="btn btn-info" (click)="navigateToLogout()">Wyloguj</button>
    </div>
</div>

<div class="main">
    <div class="card main-card" *ngFor="let news of newses">
        <div class="card-body">
            <div id="content" [innerHTML]="news.text | safeHtml" class="card-text"></div>
            <h2>Zamieszczono: {{news.date | date: 'dd/MM/yyyy'}}</h2>
            <button type="button" class="btn btn-success" (click)="deleteNews(news)" *ngIf="GetActiveUser()">Usuń</button>
            <button type="button" class="btn btn-warning" (click)="openModalEdit(news)" *ngIf="GetActiveUser()">Edytuj</button>
        </div>
    </div>
</div>
